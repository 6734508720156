



















































































import { Component, Inject } from 'vue-property-decorator';
import TextInput from '@/components/form/TextInput.vue';
import { AddUserInput, EditPropertyInput, TreeNodeType } from '@/types/iot-portal';
import NodeEditor from '../NodeEditor.vue';
import { get, omit, set } from 'lodash';
import PropertyBuildingEditor from './PropertyBuildingEditor.vue';
import { NodeEditorEditNode, NODE_EDITOR_EDIT_NODE } from '../model';

import { Property, PropertySubdivision } from './model';
import { BooleanProp, StringProp } from '@/util/prop-decorators';
import query from '../enterprise-project-customer-contact-query.gql';
import {
  AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQuery,
  AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQueryVariables,
} from '../__generated__/AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQuery';

//TODO improve templates in associated components

@Component({
  components: {
    TextInput,
    PropertyBuildingEditor,
  },
  apollo: {
    enterpriseProjectCustomerContact: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: PropertyEditor): AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQueryVariables {
        return { treeNodeId: this.node.id, treeNodeType: TreeNodeType.Property };
      },
    },
  },
})
export default class PropertyEditor extends NodeEditor {
  @StringProp(true)
  private readonly customerId!: string;

  @BooleanProp(true)
  private readonly canEditContract!: boolean;

  private readonly enterpriseProjectCustomerContact?: AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQuery['enterpriseProjectCustomerContact'];

  private get property(): Property {
    return this.node as Property;
  }

  private get propertySubdivisions(): PropertySubdivision[] {
    return this.property.children.items ?? [];
  }

  private get latitude(): number | undefined {
    return get(this.property, 'location.latitude');
  }

  private set latitude(value: number | undefined) {
    set(this.property, 'location.latitude', value);
  }

  private get longitude(): number | undefined {
    return get(this.property, 'location.longitude');
  }

  private set longitude(value: number | undefined) {
    set(this.property, 'location.longitude', value);
  }

  private get street(): string | undefined {
    return get(this.property, 'address.street');
  }

  private set street(value: string | undefined) {
    set(this.property, 'address.street', value);
  }

  private get postal(): string | undefined {
    return get(this.property, 'address.postal');
  }

  private set postal(value: string | undefined) {
    set(this.property, 'address.postal', value);
  }

  private get locality(): string | undefined {
    return get(this.property, 'address.locality');
  }

  private set locality(value: string | undefined) {
    set(this.property, 'address.locality', value);
  }

  private get country(): string | undefined {
    return get(this.property, 'address.country');
  }

  private set country(value: string | undefined) {
    set(this.property, 'address.country', value);
  }

  private get loading(): boolean {
    return this.$apollo.queries.enterpriseProjectCustomerContact.loading;
  }

  private get customerContact(): string | null {
    if (!this.enterpriseProjectCustomerContact) {
      return null;
    }

    const title = this.enterpriseProjectCustomerContact.title;
    const firstName = this.enterpriseProjectCustomerContact.firstName;
    const surname = this.enterpriseProjectCustomerContact.surname;
    const email = this.enterpriseProjectCustomerContact.email;
    const phoneNumber = this.enterpriseProjectCustomerContact.phoneNumber;

    const customerContact = `${title} ${firstName} ${surname} - ${email}, ${phoneNumber}`;

    return customerContact;
  }

  private async onEdit(): Promise<void> {
    const input: EditPropertyInput = {
      ...omit(this.property, ['__typename', 'technicalContact', 'children', 'contract']),
      address: omit(this.property.address, '__typename'),
      floors: this.property.floors.map((floor) => omit(floor, '__typename')),
      location: this.property.location
        ? {
            longitude: Number(this.property.location.longitude),
            latitude: Number(this.property.location.latitude),
          }
        : null,
      technicalContactId: this.property.technicalContact?.id ?? null,
    };
    await this.nodeEditorEditNode(
      {
        type: 'Property',
        input,
        technicalContactData: (this.property.technicalContact as AddUserInput) ?? undefined,
      },
      this.property.contract.id,
    );
  }

  @Inject(NODE_EDITOR_EDIT_NODE)
  private nodeEditorEditNode!: NodeEditorEditNode;
}
