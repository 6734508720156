






























import { Component, Inject } from 'vue-property-decorator';
import TextInput from '@/components/form/TextInput.vue';
import NodeEditor from '../NodeEditor.vue';
import { NodeEditorEditNode, NODE_EDITOR_EDIT_NODE } from '../model';
import { AddUserInput, EditDirectoryInput, TreeNodeType } from '@/types/iot-portal';

import { Directory } from './model';
import query from '../enterprise-project-customer-contact-query.gql';
import {
  AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQuery,
  AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQueryVariables,
} from '../__generated__/AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQuery';

@Component({
  components: { TextInput },
  apollo: {
    enterpriseProjectCustomerContact: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: DirectoryEditor): AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQueryVariables {
        return { treeNodeId: this.node.id, treeNodeType: TreeNodeType.Directory };
      },
    },
  },
})
export default class DirectoryEditor extends NodeEditor {
  private get directory(): Directory {
    return this.node as Directory;
  }

  private readonly enterpriseProjectCustomerContact?: AppAdminCustomerManagePropertiesEnterpriseProjectCustomerContactQuery['enterpriseProjectCustomerContact'];

  private get loading(): boolean {
    return this.$apollo.queries.enterpriseProjectCustomerContact.loading;
  }

  private get customerContact(): string | null {
    if (!this.enterpriseProjectCustomerContact) {
      return null;
    }

    const title = this.enterpriseProjectCustomerContact.title;
    const firstName = this.enterpriseProjectCustomerContact.firstName;
    const surname = this.enterpriseProjectCustomerContact.surname;
    const email = this.enterpriseProjectCustomerContact.email;
    const phoneNumber = this.enterpriseProjectCustomerContact.phoneNumber;

    const customerContact = `${title} ${firstName} ${surname} - ${email}, ${phoneNumber}`;

    return customerContact;
  }

  private async onEdit(): Promise<void> {
    if (!this.directory) {
      return;
    }
    const input: EditDirectoryInput = {
      id: this.directory.id,
      name: this.directory.name,
      order: Number(this.directory.order),
      externalId: this.directory.externalId,
      technicalContactId: this.directory.technicalContact?.id ?? null,
    };
    await this.editNode({
      type: 'Directory',
      input,
      technicalContactData: (this.directory.technicalContact as AddUserInput) ?? undefined,
    });
  }

  @Inject(NODE_EDITOR_EDIT_NODE)
  private editNode!: NodeEditorEditNode;
}
